import Icon, {
  ApiOutlined,
  CheckCircleFilled,
  DeleteOutlined,
  DisconnectOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Col,
  Form,
  FormInstance,
  List,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
} from 'antd'
import {
  isConnected,
  isDisconnected,
  requiresAuth,
} from 'constants/connections'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { MdOpenInNew } from 'react-icons/md'
import styled from 'styled-components'
import styles, { FlexCol, FlexRow } from 'styles'
import { Connection, ConnectionProvider, ConnectionTitle } from '.'
import {
  connectConnection,
  connectConnectionCallback,
  disconnectConnection,
  reconnectConnection,
} from './connection.api'
import { removeAdvisorSuffix } from './connection.utils'

const StyledCard = styled.div`
  .learn-more {
    > span {
      margin-bottom: 0px;
    }

    margin-top: 10px;
    &.reauthenticate {
      margin-top: 16px;
    }

    width: fit-content;
    color: white;
    .icon {
      margin-left: 6px;
    }
  }
  .description {
    flex: 1;
    .markdown {
      border-right: 1px solid ${styles.colors.layoutBorder};
      padding-right: 24px;
    }
  }
  .overview {
    margin-left: 24px;
    flex: 0 0 200px;
    height: 100%;
  }

  .medium {
    font-size: 16px;
  }

  .ant-list-item-meta-title {
    font-family: Lato !important;
    font-size: 16px !important;
  }
  .ant-list-item-action > li > div {
    font-family: Lato !important;
    font-size: 16px !important;
    color: black !important;
    cursor: default;
  }
`

interface LearnMoreProps {
  learnMoreLink: string
}

const LearnMore: React.FC<LearnMoreProps> = ({ learnMoreLink }) => (
  <Button
    onClick={() => window.open(learnMoreLink, '_blank')}
    icon={<Icon component={MdOpenInNew} />}
  >
    Learn More
  </Button>
)

interface ConnectProps {
  connecting: boolean
  onClick: () => void
}

const Connect: React.FC<ConnectProps> = ({ connecting, onClick }) => (
  <Button
    loading={connecting}
    onClick={onClick}
    type="primary"
    icon={<ApiOutlined />}
  >
    {connecting ? 'Connecting' : 'Connect'}
  </Button>
)

interface ReconnectProps {
  name: string
  reconnecting: boolean
  onClick: () => void
}

const Reconnect: React.FC<ReconnectProps> = ({
  name,
  reconnecting,
  onClick,
}) => (
  <Button
    loading={reconnecting}
    onClick={onClick}
    type="primary"
    danger
    icon={<ApiOutlined />}
  >
    Reconnect {name.toUpperCase()}
  </Button>
)

interface RemoveProps {
  disconnecting: boolean
  onClick: () => void
}

const Remove: React.FC<RemoveProps> = ({ disconnecting, onClick }) => (
  <Button
    loading={disconnecting}
    onClick={onClick}
    danger
    icon={<DeleteOutlined />}
  >
    {disconnecting ? 'Removing Connection' : 'Remove Connection'}
  </Button>
)

interface GetStartedProps {
  onClick: () => void
}

const GetStarted: React.FC<GetStartedProps> = ({ onClick }) => (
  <Button onClick={onClick} type="primary" icon={<ApiOutlined />}>
    Get Started
  </Button>
)

interface DisconnectProps {
  disconnecting: boolean
  onClick: () => void
}

const Disconnect: React.FC<DisconnectProps> = ({ disconnecting, onClick }) => (
  <Button
    loading={disconnecting}
    className="disconnect"
    onClick={onClick}
    danger
    icon={<DisconnectOutlined />}
  >
    {disconnecting ? 'Disconnecting' : 'Disconnect'}
  </Button>
)

const Uninstall = styled(FlexCol)`
  border: 1px solid ${styles.colors.error};
  padding: 16px 24px 24px;

  p {
    margin-bottom: 0px !important;
  }
  .disconnect {
    margin-top: 24px !important;
  }
`

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`

const getAuthCode = async (code: string, provider: ConnectionProvider) => {
  await connectConnectionCallback(provider, code)

  const path = removeAdvisorSuffix(provider)
  global.router.goto(`/settings/integrations/${path}`)
}

interface ConnectionBuilderStaticProps {
  LearnMore?: React.FC<LearnMoreProps>
}

export interface ConnectionBuilderProps {
  provider: ConnectionProvider
  title: ConnectionTitle
  connection?: Connection
  key1?: string
  key2?: string
  learnMoreLink: string
  features?: string[]
  renderSettings: (form: FormInstance, onFinish: () => void) => void
}

export const ConnectionBuilder: React.FC<ConnectionBuilderProps> &
  ConnectionBuilderStaticProps = observer(
  ({
    provider,
    title,
    connection,
    key1,
    key2,
    learnMoreLink,
    features = [],
    renderSettings,
  }) => {
    const [disconnecting, isDisconnecting] = useState(false)
    const [reconnecting, isReconnecting] = useState(false)
    const [connecting, isConnecting] = useState(false)
    const [tab, setTab] = useState('overview')
    const [form] = Form.useForm()

    useEffect(() => {
      const { code, state } = global.router.params
      if (code) {
        getAuthCode(code, state)
      }
    }, [])

    const onFinish = useCallback(() => {
      form.validateFields().then(async (values: any) => {
        isConnecting(true)
        await connectConnection(provider, {
          clientId: key1 ?? values.key1,
          clientSecret: key2 ?? values.key2,
        })
        isConnecting(false)
      })
    }, [form, provider, key1, key2])

    const onDisconnect = useCallback(() => {
      Modal.confirm({
        title,
        content: 'Are you sure you want to disconnect this connection?',
        cancelText: 'Cancel',
        okType: 'danger',
        okText: 'Yes, Remove!',
        onOk: async () => {
          isDisconnecting(true)
          await disconnectConnection(provider)
          isDisconnecting(false)
        },
      })
    }, [provider, title])

    const onReconnect = useCallback(async () => {
      isReconnecting(true)
      await reconnectConnection(provider)
      isReconnecting(false)
    }, [provider])

    return (
      <StyledCard>
        <Tabs
          animated={{ inkBar: true, tabPane: false }}
          onChange={(t) => setTab(t)}
          activeKey={tab}
          size="middle"
        >
          <Tabs.TabPane tab="Overview" key="overview">
            <FlexRow align="flex-start">
              <FlexCol className="description">
                {requiresAuth(connection?.state) && (
                  <StyledAlert
                    message={`There's a problem with your credentials`}
                    type="error"
                    description={`We need to send you to ${title}, to update your login details.`}
                    showIcon
                  />
                )}
                <div className="markdown medium">
                  {requiresAuth(connection?.state) ? (
                    "You'll be redirected back to Pulse360 when the connection has been re-established."
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: connection?.desc ?? '',
                      }}
                    />
                  )}
                </div>
                <FlexRow style={{ marginTop: 24 }}>
                  <Space>
                    {isDisconnected(connection?.state) && (
                      <GetStarted onClick={() => setTab('setup')} />
                    )}
                    {requiresAuth(connection?.state) && (
                      <Reconnect
                        reconnecting={reconnecting}
                        onClick={onReconnect}
                        name={title}
                      />
                    )}
                    {learnMoreLink && isDisconnected(connection?.state) && (
                      <LearnMore learnMoreLink={learnMoreLink} />
                    )}
                    {!isDisconnected(connection?.state) && (
                      <Remove
                        disconnecting={disconnecting}
                        onClick={onDisconnect}
                      />
                    )}
                  </Space>
                </FlexRow>
              </FlexCol>
              <div className="overview">
                <List pagination={false}>
                  {features.map((feat) => (
                    <List.Item
                      key={feat}
                      actions={[
                        <CheckCircleFilled
                          style={{ color: styles.colors.green }}
                          key={feat}
                        />,
                      ]}
                    >
                      <List.Item.Meta title={feat} />
                    </List.Item>
                  ))}
                </List>
              </div>
            </FlexRow>
          </Tabs.TabPane>

          {!requiresAuth(connection?.state) && (
            <Tabs.TabPane
              tab={isConnected(connection?.state) ? 'Disconnect' : 'Connect'}
              key="setup"
            >
              {isConnected(connection?.state) && (
                <Uninstall style={{ marginTop: 10 }}>
                  <Typography.Title
                    level={3}
                    style={{ color: styles.colors.error }}
                  >
                    Danger Zone
                  </Typography.Title>
                  <p>Disconnect {title}?</p>
                  <p>
                    This will remove the connection and revoke access to all
                    resources.
                  </p>
                  <Row>
                    <Col>
                      <Disconnect
                        disconnecting={disconnecting}
                        onClick={onDisconnect}
                      />
                    </Col>
                  </Row>
                </Uninstall>
              )}

              {isDisconnected(connection?.state) && (
                <FlexCol>
                  {renderSettings(form, onFinish)}
                  <Row>
                    <Col>
                      <Connect connecting={connecting} onClick={onFinish} />
                    </Col>
                  </Row>
                </FlexCol>
              )}
            </Tabs.TabPane>
          )}
        </Tabs>
      </StyledCard>
    )
  },
)

ConnectionBuilder.LearnMore = LearnMore
