import { SmartFieldProvider } from 'components/drawers/Smartfields/types'
import { isConnected } from 'constants/connections'
import { useEffect, useMemo, useState } from 'react'
import { Connection, ConnectionProvider, connectionStore } from '.'
import {
  ConnectionConfiguration,
  getConnectionConfig,
} from './connection.config'

export const useConnection = (
  provider: ConnectionProvider | SmartFieldProvider,
) => {
  const [connected, setConnected] = useState(true)
  const [connection, setConnection] = useState<Connection>()
  const [cfg, setConfiguration] = useState<ConnectionConfiguration>()

  useEffect(() => {
    const conn = connectionStore.getConnection(provider)
    const configuration = getConnectionConfig(provider)

    setConnection(conn)
    setConnected((conn && isConnected(conn.state)) ?? false)
    setConfiguration(configuration)
  }, [connection, provider])

  return useMemo(
    () => ({
      connected,
      connection,
      cfg,
    }),
    [connected, connection, cfg],
  )
}
