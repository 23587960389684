import { Layout } from 'antd'
import styled from 'styled-components'

const StyledLayout = styled(Layout)`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  justify-content: center;
  align-items: center;
`

interface CenterLayoutProps {
  children: React.ReactNode
}

export const CenterLayout: React.FC<CenterLayoutProps> = ({ children }) => (
  <StyledLayout>
    <Layout.Content>{children}</Layout.Content>
  </StyledLayout>
)
